import { latterTiles, maskedTiles } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  masked?: boolean
  row?: number
  latters?: boolean
}

export const EmptyRow = ({
  row = -10,
  masked = false,
  latters = false,
}: Props) => {
  const emptyCells = Array.from(Array(/*solution.length*/ 5))

  return (
    <div className="mb-1 flex justify-center">
      {emptyCells.map((_, i) => (
        <Cell
          key={i}
          masked={masked ? maskedTiles.includes(5 * row + i) : false}
          value={
            latters && latterTiles[1].includes(row * 5 + i)
              ? latterTiles[0][Math.floor((5 * row + i) / 5)]
              : undefined
          }
        />
      ))}
    </div>
  )
}
