import classnames from 'classnames'
import { ReactNode, useState } from 'react'

import { REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'
import { CharStatus } from '../../lib/statuses'

type Props = {
  children?: ReactNode
  value: string
  width?: number
  status?: CharStatus
  onClick: (value: string) => void
  isRevealing?: boolean
  isMastermind?: boolean
}

export const Key = ({
  children,
  status,
  width = 40,
  value,
  onClick,
  isRevealing,
  isMastermind = false,
}: Props) => {
  const keyDelayMs = REVEAL_TIME_MS * 5
  const isHighContrast = getStoredIsHighContrastMode()
  const isAll = status && status.indexOf('_') > 0
  const green_color = 'rgb(34 197 94)',
    yellow_color = 'rgb(234 179 8)',
    gray_color = 'rgb(30 41 59)'
  const colors_status = isAll
    ? status
        ?.replaceAll('absent', gray_color)
        .replaceAll('present', yellow_color)
        .replaceAll('correct', green_color)
        .split('_')
    : ''
  const exclude_status = ['', 'excluded', 'correct']
  const [excluded, setExcluded] = useState(0)
  const classes = classnames(
    'text-2xl xxshort:h-8 xxshort:w-8 xxshort:text-xxs xshort:w-10 xshort:h-10 flex short:h-12 h-14 items-center justify-center rounded mx-0.5 text-m font-bold cursor-pointer select-none dark:text-white',
    {
      'transition ease-in-out': isRevealing,
      'bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400':
        !status && !(excluded === 2),
      'bg-slate-400 dark:bg-slate-800 text-white':
        status === 'absent' && excluded === 0,
      'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white':
        (status === 'correct' || (excluded === 2 && isMastermind)) &&
        isHighContrast,
      'bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 text-white':
        status === 'present' && isHighContrast,
      'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white':
        (status === 'correct' || (excluded === 2 && isMastermind)) &&
        !isHighContrast,
      'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white':
        status === 'present' && !isHighContrast,
    },
    isMastermind ? exclude_status[excluded] : ''
  )

  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
    width: `${width}px`,
    ...(isAll
      ? {
          background: `linear-gradient(to left , ${colors_status[0]} 0,   ${colors_status[0]} 25%, 
      ${colors_status[1]} 25%,${colors_status[1]} 50%, 
      ${colors_status[2]} 50%,${colors_status[2]} 75%,
      ${colors_status[3]} 75%,${colors_status[3]} 100%)`,
        }
      : {}),
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event.type === 'contextmenu' && isMastermind) {
      event.preventDefault()
      setExcluded((excluded + 1) % 3)
      console.log(value, excluded)
    } else {
      onClick(value)
      event.currentTarget.blur()
    }
  }

  return (
    <button
      style={styles}
      aria-label={`${value}${status ? ' ' + status : ''}`}
      className={classes}
      onClick={handleClick}
      onContextMenu={handleClick}
    >
      {children || value}
    </button>
  )
}
