import { latterTiles, maskedTiles, unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  guess: string
  className: string
  row?: number
  masked?: boolean
  latters?: boolean
}

export const CurrentRow = ({
  guess,
  className,
  row = -10,
  masked = false,
  latters = false,
}: Props) => {
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(5 - splitGuess.length))
  const classes = `flex justify-center mb-1 ${className}`

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          masked={masked && maskedTiles.includes(5 * row + i)}
        />
      ))}
      {emptyCells.map((_, i) => (
        <Cell
          key={i}
          masked={
            masked && maskedTiles.includes(5 * row + i + splitGuess.length)
          }
          value={
            latters && latterTiles[1].includes(5 * row + i + splitGuess.length)
              ? latterTiles[0][
                  Math.floor((5 * row + i + splitGuess.length) / 5)
                ]
              : undefined
          }
        />
      ))}
    </div>
  )
}
