import { MAX_CHALLENGES } from '../../constants/settings'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

type Props = {
  solution: string
  guesses: string[]
  currentGuess: string
  isRevealing?: boolean
  currentRowClassName: string
  isGameDone?: boolean
  hidden: boolean
  grid_id: number
  focus?: number
  update_focus: (value: number) => void
  isHardMode: boolean
}

export const GridMasked = ({
  solution,
  guesses,
  currentGuess,
  isRevealing,
  currentRowClassName,
  isGameDone = false,
  hidden,
  grid_id,
  focus = 4,
  update_focus,
  isHardMode,
}: Props) => {
  const solution_position = guesses.indexOf(solution)
  const empties =
    guesses.length === MAX_CHALLENGES && solution_position === -1
      ? []
      : solution_position > -1
        ? Array.from(Array(MAX_CHALLENGES - (solution_position + 1)))
        : Array.from(Array(MAX_CHALLENGES - (guesses.length + 1)))
  const answer_classes =
    'mb-2 xxshort:w-11 xxshort:h-11 short:text-2xl short:w-12 short:h-12 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white text-white ' +
    (solution_position === -1
      ? 'present shadowed bg-yellow-500 text-white border-yellow-500'
      : 'correct shadowed bg-green-500 border-green-500')

  return (
    <div className={`flex  ${grid_id === focus ? ' big-box' : ''}`}>
      <div className="m-4">
        <>
          {isGameDone ? (
            <div className={answer_classes}> {solution}</div>
          ) : (
            <></>
          )}

          {guesses.map((guess, i) =>
            solution_position === -1 || i <= solution_position ? (
              <CompletedRow
                key={i}
                row={i}
                solution={solution}
                guess={guess}
                isRevealing={isRevealing && guesses.length - 1 === i}
                // masked={true}
              />
            ) : (
              <></>
            )
          )}
          {guesses.length < MAX_CHALLENGES && !guesses.includes(solution) && (
            <CurrentRow
              guess={currentGuess}
              className={currentRowClassName}
              row={guesses.length}
              masked={true}
            />
          )}
          {empties.map((_, i) => (
            <EmptyRow
              key={i}
              row={
                (solution_position === -1
                  ? guesses.length
                  : solution_position) +
                1 +
                i
              }
              masked={true}
            />
          ))}
        </>

        {/* 
        {!isHardMode ? (
          <div
            className={
              ' xxshort:text-xxs shadowed mx-0.5 flex h-14 cursor-pointer select-none items-center justify-center rounded ' +
              (focus === grid_id
                ? 'border-2 border-solid border-gray-200'
                : 'bg-slate-200 bg-opacity-50 hover:bg-slate-300 active:bg-slate-400 dark:bg-slate-600') +
              ' text-3xl font-bold  dark:text-white short:h-12 xshort:h-10 xshort:w-10 xxshort:h-8 xxshort:w-8 '
            }
            onClick={() =>
              focus === grid_id ? update_focus(4) : update_focus(grid_id)
            }
          >
            {' '}
            تركيز{' '}
          </div>
        ) : (
          <></>
        )} 
         */}
      </div>
    </div>
  )
}
