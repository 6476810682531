import { UAParser } from 'ua-parser-js'

import { MAX_CHALLENGES } from '../constants/settings'
import { GAME_TITLE } from '../constants/strings'
import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

export const shareStatus = (
  solution: string[],
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  handleShareToClipboard: () => void,
  handleShareFailure: () => void
) => {
  const textToShare =
    `${GAME_TITLE} (${solutionIndex}) ${
      lost ? 'X' : guesses.length
    }/${MAX_CHALLENGES}${isHardMode ? ' 🥇' : ''}\n\n` +
    generateEmojiGrid(
      solution,
      guesses,
      getEmojiTiles(isDarkMode, isHighContrastMode)
    )

  const shareData = { text: textToShare }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(handleShareToClipboard)
          .catch(handleShareFailure)
      } else {
        handleShareFailure()
      }
    }
  } catch (error) {
    handleShareFailure()
  }
}

export const generateEmojiGrid = (
  solution: string[],
  guesses: string[],
  tiles: string[]
) => {
  //   return guesses
  //     .map((guess) => {
  //       const status = getGuessStatuses(solution, guess)
  //       const splitGuess = unicodeSplit(guess)

  //       return splitGuess
  //         .map((_, i) => {
  //           switch (status[i]) {
  //             case 'correct':
  //               return tiles[0]
  //             case 'present':
  //               return tiles[1]
  //             default:
  //               return tiles[2]
  //           }
  //         })
  //         .join('')
  //     })
  //     .join('\n')
  // }
  return (
    '4️⃣3️⃣2️⃣1️⃣\n' +
    guesses
      .map((guess, i) => {
        const status = solution.map((sol) => getGuessStatuses(sol, guess))

        return status
          .map((sol, wordindx) => {
            if (guesses.slice(0, i).includes(solution[wordindx])) {
              return tiles[2]
            } else if (sol.every((v) => v === 'correct')) {
              return tiles[0]
            } else if (sol.filter((v) => v === 'absent').length > 2) {
              return tiles[2]
            } else {
              return tiles[1]
            }
          })
          .reverse()
          .join('')
      })
      .join('\n')
  )
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}

const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? '🟧' : '🟩')
  tiles.push(isHighContrastMode ? '🟦' : '🟨')
  tiles.push(isDarkMode ? '⬛' : '⬜')
  return tiles
}
