import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="طريقة اللعب" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        خمن الأربع كلمات في 10 محاولات، عقب كل محاولة ستتلون المربعات لتشير لمدى
        قربك من الكلمات الصحيحة
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="ص"
          status="correct"
        />
        <Cell value="ا" isCompleted={true} />
        <Cell value="ع" isCompleted={true} />
        <Cell value="د" isCompleted={true} />
        <Cell value="ة" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        حرف الصاد في مكانه الصحيح.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="ا" isCompleted={true} />
        <Cell value="س" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="م"
          status="present"
        />
        <Cell value="ا" isCompleted={true} />
        <Cell value="ء" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        حرف الميم موجود في الكلمة ولكن في مكان مختلف.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="م" isCompleted={true} />
        <Cell value="ص" isCompleted={true} />
        <Cell value="ن" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="و" status="absent" />
        <Cell value="ع" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        حرف الواو غير موجود في الكلمة.
      </p>
      <p className="text-m m-2 text-gray-500 dark:text-gray-300">
        {' '}
        ## في الوضع الصعب ستكون الكلمات مخفية وتنكشف بالتوالي عند إصابة الكلمة
        السابقة
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        تم تطوير هذه النسخة من اللعبة الشهيرة wordle،
        <br />
        من مجتمع Arabian_MaD - {' عربي جنون '} <br />
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="font-bold underline"
        >
          تفقد المصدر البرمجي
        </a>{' '}
      </p>
    </BaseModal>
  )
}
