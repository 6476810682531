import './App.css'

import { ClockIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useMemo, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { GridMasked } from './components/grid/GridMasked'
import { GridMastermind } from './components/grid/GridMastermind'
import { Key } from './components/keyboard/Key'
import { Keyboard } from './components/keyboard/Keyboard'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
// import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { Navbar } from './components/navbar/Navbar'
import {
  DATE_LOCALE,
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { isInAppBrowser } from './lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  // findFirstUnusedReveal,
  getGameDate,
  getIsLatestGame,
  isWinningWord,
  isWordInWordList,
  latterTiles,
  setGameDate,
  solution,
  solutionGameDate,
  unicodeLength,
} from './lib/words'

function App() {
  const isLatestGame = getIsLatestGame()
  const gameDate = getGameDate()
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  // const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const setIsMigrateStatsModalOpen = (value?: boolean) => {} //////  quick solution
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
        ? true
        : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if (loaded?.solution.join('') !== solution.join('')) {
      return []
    }

    const gameWasWon = solution.every((element) =>
      loaded.guesses.includes(element)
    )
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      showErrorAlert(CORRECT_WORD_MESSAGE(solution[0]), {
        persist: true,
      })
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )
  const [isMastermind, setIsMastermind] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'mastermind'
      : false
  )
  const [isMasked, setIsMasked] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'masked'
      : false
  )

  const [isLatters, setIsLatters] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'latters'
      : false
  )
  const [solved, setSolved] = useState([0, 0, 0, 0])
  const [focus, setFocus] = useState(4)

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage(true)) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  const [isMobile, setIsMobile] = useState(false)
  const [gridShow, setGridShow] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1300)
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const [modeChange, setModeChange] = useState(false)

  useEffect(() => {
    setIsHardMode(false)
    setIsMastermind(false)
    setIsMasked(false)
    setIsLatters(false)
    setIsLatters(false)

    let new_mode = localStorage.getItem('gameMode')

    if (new_mode === 'hard') {
      setIsHardMode(true)
      setFocus(4)
    } else if (new_mode === 'mastermind') {
      setIsMastermind(true)
      setFocus(5)
    } else if (new_mode === 'masked') {
      setIsMasked(true)
      setFocus(5)
    } else if (new_mode === 'latters') {
      setIsLatters(true)
      setFocus(4)
    } else {
      setFocus(4)
    }

    setModeChange(false)
  }, [modeChange])

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      // setIsHardMode(isHard)
      // setIsMastermind(false)
      // setIsMasked(false)
      // setFocus(4)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
      setModeChange(true)
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }
  const handleMastermind = (isMastermindCheck: boolean) => {
    if (guesses.length === 0) {
      // setIsMastermind(isMastermindCheck)
      // setIsHardMode(false)
      // setIsMasked(false)
      // setFocus(isMastermindCheck ? 5 : 4)
      localStorage.setItem(
        'gameMode',
        isMastermindCheck ? 'mastermind' : 'normal'
      )
      setModeChange(true)
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }
  const handleMasked = (isMaskedCheck: boolean) => {
    if (guesses.length === 0) {
      // setIsMastermind(false)
      // setIsHardMode(false)
      // setIsMasked(isMaskedCheck)
      // setFocus(isMaskedCheck ? 5 : 4)
      localStorage.setItem('gameMode', isMaskedCheck ? 'masked' : 'normal')
      setModeChange(true)
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }
  const handleLatters = (isLattersCheck: boolean) => {
    if (guesses.length === 0) {
      localStorage.setItem('gameMode', isLattersCheck ? 'latters' : 'normal')
      setModeChange(true)
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * solution.length

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      })
    }

    if (isGameLost) {
      setTimeout(
        () => {
          setIsStatsModalOpen(true)
        },
        (solution.length + 1) * REVEAL_TIME_MS
      )
    }
  }, [isGameWon, isGameLost, showSuccessAlert])

  useMemo(() => {
    if (isGameLost || isGameWon) {
      const key = 'archivedState'
      const archivedState = localStorage.getItem(key)
      const archivedStateList = archivedState ? JSON.parse(archivedState) : []
      const dateAsString = gameDate.toISOString()

      if (!archivedStateList.includes(dateAsString)) {
        archivedStateList.push(dateAsString)
        localStorage.setItem(key, JSON.stringify(archivedStateList))
      }
    }
  }, [isGameLost, isGameWon, gameDate])

  const onChar = (value: string) => {
    if (isLatters) {
      let pointer = guesses.length * 5 + currentGuess.length
      if (latterTiles[1].includes(pointer)) {
        if (unicodeLength(`${currentGuess}`) < 4) {
          setCurrentGuess(
            `${currentGuess}${latterTiles[0][Math.floor(pointer / 5)]}${value}`
          )
        }
      } else if (
        unicodeLength(`${currentGuess}`) === 3 &&
        latterTiles[1].includes(pointer + 1)
      ) {
        setCurrentGuess(
          `${currentGuess}${value}${latterTiles[0][Math.floor(pointer / 5)]}`
        )
      } else {
        if (
          unicodeLength(`${currentGuess}${value}`) <= 5 &&
          guesses.length < MAX_CHALLENGES &&
          !isGameWon
        ) {
          setCurrentGuess(`${currentGuess}${value}`)
        }
      }
    } else {
      if (
        unicodeLength(`${currentGuess}${value}`) <= 5 &&
        guesses.length < MAX_CHALLENGES &&
        !isGameWon
      ) {
        setCurrentGuess(`${currentGuess}${value}`)
      }
    }
  }
  const onDelete = () => {
    if (isLatters) {
      let pointer = guesses.length * 5 + currentGuess.length - 1
      if (
        (latterTiles[1].includes(pointer) ||
          latterTiles[1].includes(pointer - 1)) &&
        currentGuess.length > 1
      ) {
        setCurrentGuess(
          new GraphemeSplitter()
            .splitGraphemes(currentGuess)
            .slice(0, -2)
            .join('')
        )
        // }else if( latterTiles[1].includes(pointer-1 )  && currentGuess.length === 1  ){
        //   setCurrentGuess(
        //             new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -2).join('')
        //           )
      } else {
        setCurrentGuess(
          new GraphemeSplitter()
            .splitGraphemes(currentGuess)
            .slice(0, -1)
            .join('')
        )
      }
    } else {
      setCurrentGuess(
        new GraphemeSplitter()
          .splitGraphemes(currentGuess)
          .slice(0, -1)
          .join('')
      )
    }
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === /*solution.length*/ 5)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    setTimeout(() => {
      setSolved(
        solution.map((solution_word) =>
          guesses.concat(currentGuess).includes(solution_word) ? 1 : 0
        )
      )
    }, 5 * 350)

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * /*solution.length*/ 5)

    const winningWord = isMastermind
      ? guesses.concat(currentGuess).includes(solution[0])
      : isWinningWord(guesses.concat(currentGuess))

    if (
      unicodeLength(currentGuess) === /*solution.length*/ 5 &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        setIsGameLost(true)
        showErrorAlert(CORRECT_WORD_MESSAGE(solution[0]), {
          //////////////// need to be changed
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })
      }
    }
  }
  useEffect(() => {
    if (isHardMode) {
      setFocus(solved.includes(0) ? solved.indexOf(0) : 4)
    }
  }, [solved, isHardMode])

  const render_mode = () => {
    if (isMastermind) {
      return (
        <>
          <GridMastermind
            key={`m-word`}
            solution={solution[0]}
            guesses={guesses}
            currentGuess={currentGuess}
            isRevealing={isRevealing}
            currentRowClassName={currentRowClass}
          />
        </>
      )
    } else if (isMasked) {
      return solution.map((sol, indx) => (
        <GridMasked
          key={`${indx}-word`}
          solution={sol}
          guesses={guesses}
          currentGuess={currentGuess}
          isRevealing={isRevealing}
          currentRowClassName={currentRowClass}
          isGameDone={isGameLost || isGameWon}
          hidden={isHardMode && focus < indx}
          grid_id={indx}
          focus={focus}
          update_focus={setFocus}
          isHardMode={isHardMode}
        />
      ))
    } else {
      return (
        <>
          {solution.map((sol, indx) => {
            return (isMobile && indx === gridShow) || !isMobile ? (
              <Grid
                key={`${indx}-word`}
                solution={sol}
                guesses={guesses}
                currentGuess={currentGuess}
                isRevealing={isRevealing}
                currentRowClassName={currentRowClass}
                isGameDone={isGameLost || isGameWon}
                hidden={isHardMode && focus < indx}
                grid_id={indx}
                focus={focus}
                update_focus={setFocus}
                isHardMode={isHardMode}
                isLatters={isLatters}
              />
            ) : (
              <></>
            )
          })}
        </>
      )
    }
  }

  return (
    <Div100vh>
      <div className="flex h-full flex-col">
        <Navbar
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsStatsModalOpen={setIsStatsModalOpen}
          setIsDatePickerModalOpen={setIsDatePickerModalOpen}
          setIsSettingsModalOpen={setIsSettingsModalOpen}
        />

        {!isLatestGame && (
          <div className="flex items-center justify-center">
            <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
            <p className="text-base text-gray-600 dark:text-gray-300">
              {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
            </p>
          </div>
        )}

        <div className="mx-auto flex w-full grow flex-col px-1 pb-8 pt-2 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
          {isMobile && (
            <div className="flex items-center justify-center">
              {Array(4)
                .fill(0)
                .map((_, i) => (
                  <Key
                    key={i}
                    value={1 + i + ''}
                    status={i === gridShow ? 'correct' : 'absent'}
                    onClick={() => setGridShow(i)}
                  />
                ))}
            </div>
          )}
          <div className="grow- flex flex-row justify-center pb-6 short:pb-2">
            {render_mode()}
          </div>
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            solution={solution}
            guesses={guesses}
            isRevealing={isRevealing}
            focus={isMastermind || isMasked ? 5 : focus}
            update_focus={setFocus}
            isHardMode={isHardMode}
            isMastermind={isMastermind}
          />
          <InfoModal
            isOpen={isInfoModalOpen}
            handleClose={() => setIsInfoModalOpen(false)}
          />
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            solution={solution}
            guesses={guesses}
            gameStats={stats}
            isLatestGame={isLatestGame}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
            handleShareFailure={() =>
              showErrorAlert(SHARE_FAILURE_TEXT, {
                durationMs: LONG_ALERT_TIME_MS,
              })
            }
            handleMigrateStatsButton={() => {
              setIsStatsModalOpen(false)
              setIsMigrateStatsModalOpen(true)
            }}
            isHardMode={isHardMode}
            isDarkMode={isDarkMode}
            isHighContrastMode={isHighContrastMode}
            numberOfGuessesMade={guesses.length}
          />
          <DatePickerModal
            isOpen={isDatePickerModalOpen}
            initialDate={solutionGameDate}
            handleSelectDate={(d) => {
              setIsDatePickerModalOpen(false)
              setGameDate(d)
            }}
            handleClose={() => setIsDatePickerModalOpen(false)}
          />

          <SettingsModal
            isOpen={isSettingsModalOpen}
            handleClose={() => setIsSettingsModalOpen(false)}
            isHardMode={isHardMode}
            handleHardMode={handleHardMode}
            isMastermind={isMastermind}
            handleMastermind={handleMastermind}
            isMasked={isMasked}
            handleMasked={handleMasked}
            isLatters={isLatters}
            handleLatters={handleLatters}
            isDarkMode={isDarkMode}
            handleDarkMode={handleDarkMode}
            isHighContrastMode={isHighContrastMode}
            handleHighContrastMode={handleHighContrastMode}
          />

          <AlertContainer />
        </div>
      </div>
    </Div100vh>
  )
}

export default App
