import { useEffect } from 'react'

import { DELETE_TEXT, ENTER_TEXT } from '../../constants/strings'
import { CharStatus, getStatuses } from '../../lib/statuses'
import { Key } from './Key'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  solution: string[]
  guesses: string[]
  isRevealing?: boolean
  focus?: number
  update_focus: (value: number) => void
  isHardMode: boolean
  isMastermind: boolean
}

export const Keyboard = ({
  onChar,
  onDelete,
  onEnter,
  solution,
  guesses,
  isRevealing,
  focus = 4,
  update_focus,
  isHardMode,
  isMastermind,
}: Props) => {
  let charStatuses = Array(6) //= solution.map(solution_i => getStatuses(solution_i, guesses));
  for (let idx = 0; idx < charStatuses.length; idx++) {
    if (idx === 5) {
      charStatuses[idx] = getStatuses('     ', guesses)
    } else if (idx === 4) {
      continue
    } else {
      charStatuses[idx] = getStatuses(solution[idx], guesses)
    }
  }
  // to have all status togather for split colors on keyboard :
  //  input [{a: 1, b: 2, c: 3},
  // {a: 11, b: 22, c: 33}]
  // results :
  // {a:"1_11_111", b:"2_22_222"}
  let all_solutaion_status: { [key: string]: CharStatus } = {}
  charStatuses.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (!all_solutaion_status[key]) {
        all_solutaion_status[key] = obj[key]
      } else {
        all_solutaion_status[key] += `_${obj[key]}`
      }
    })
  })
  charStatuses[4] = all_solutaion_status

  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      } else {
        const key = e.key
        // TODO: check this test if the range works with non-english letters
        if (key.length === 1 && key >= 'ء' && key <= 'ي') {
          if (['أ', 'إ', 'آ'].includes(key)) {
            onChar('ا')
          } else {
            onChar(key)
          }
        }
      }
    }

    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  return (
    <div>
      <div className="mb-1 flex justify-center">
        {['ض', 'ص', 'ث', 'ق', 'ف', 'غ', 'ع', 'ه', 'خ', 'ح', 'ج', 'د']
          .reverse()
          .map((key) => (
            <Key
              width={70}
              value={key}
              key={key}
              onClick={onClick}
              status={charStatuses[focus][key]}
              isRevealing={isRevealing}
              isMastermind={isMastermind}
            />
          ))}
      </div>
      <div className="mb-1 flex justify-center">
        {['ش', 'س', 'ي', 'ب', 'ل', 'ا', 'ت', 'ن', 'م', 'ك', 'ط']
          .reverse()
          .map((key) => (
            <Key
              width={70}
              value={key}
              key={key}
              onClick={onClick}
              status={charStatuses[focus][key]}
              isRevealing={isRevealing}
              isMastermind={isMastermind}
            />
          ))}
      </div>
      <div className="flex justify-center">
        <Key width={110} value="ENTER" onClick={onClick}>
          {ENTER_TEXT}
        </Key>
        {['ئ', 'ء', 'ؤ', 'ر', 'ى', 'ة', 'و', 'ز', 'ظ', 'ذ']
          .reverse()
          .map((key) => (
            <Key
              width={70}
              value={key}
              key={key}
              onClick={onClick}
              status={charStatuses[focus][key]}
              isRevealing={isRevealing}
              isMastermind={isMastermind}
            />
          ))}
        <Key width={110} value="DELETE" onClick={onClick}>
          {DELETE_TEXT}
        </Key>
      </div>
    </div>
  )
}
