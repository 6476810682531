import { MAX_CHALLENGES } from '../../constants/settings'
import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

type Props = {
  solution: string
  guesses: string[]
  currentGuess: string
  isRevealing?: boolean
  currentRowClassName: string
  grid_id?: number
  focus?: number
}

export const GridMastermind = ({
  solution,
  guesses,
  currentGuess,
  isRevealing,
  currentRowClassName,
  grid_id = 0,
  focus = 4,
}: Props) => {
  const solution_position = guesses.indexOf(solution)
  const empties =
    guesses.length === MAX_CHALLENGES && solution_position === -1
      ? []
      : solution_position > -1
        ? Array.from(Array(MAX_CHALLENGES - (solution_position + 1)))
        : Array.from(Array(MAX_CHALLENGES - (guesses.length + 1)))
  const answer_classes =
    'mr-4 ml-4 m-2  h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white text-white  ' +
    (solution_position === -1
      ? 'present shadowed bg-yellow-500 text-white border-yellow-500'
      : 'correct shadowed bg-green-500 border-green-500')

  return (
    <div className="rr m-4" key={'rr'}>
      {solution_position > -1 || guesses.length === MAX_CHALLENGES ? (
        <div className={answer_classes} key={'rrr'}>
          {' '}
          {solution}
        </div>
      ) : (
        <></>
      )}

      <div className=" flex" key={'flexo'}>
        <div className="flexs m-4" key={'flexi'}>
          {guesses.map((guess, i) =>
            solution_position === -1 || i <= solution_position ? (
              <div className=" mb-1 flex" key={'flex' + i}>
                <Cell
                  key={i + '-c'}
                  value={
                    getGuessStatuses(solution, guess).filter(
                      (x) => x === 'present'
                    ).length + ''
                  }
                  status="present"
                  position={5}
                  isRevealing={isRevealing && guesses.length - 1 === i}
                />
                <Cell
                  key={i + '-cl'}
                  value={
                    getGuessStatuses(solution, guess).filter(
                      (x) => x === 'correct'
                    ).length + ''
                  }
                  status="correct"
                  position={5}
                  isRevealing={isRevealing && guesses.length - 1 === i}
                />
              </div>
            ) : (
              <></>
            )
          )}
          {guesses.length < MAX_CHALLENGES && solution_position === -1 ? (
            <div className=" mb-1 flex">
              <Cell
                key={'cn'}
                value={''}
                position={5}
                isRevealing={isRevealing}
              />
              <Cell
                key={'cr'}
                value={''}
                position={5}
                isRevealing={isRevealing}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="m-4 ">
          <>
            {guesses.map((guess, i) =>
              solution_position === -1 || i <= solution_position ? (
                <div className="flex">
                  <CompletedRow
                    key={i + 'r'}
                    solution={
                      guess === solution ||
                      solution_position > -1 ||
                      guesses.length === MAX_CHALLENGES
                        ? solution
                        : '     '
                    }
                    guess={guess}
                    isRevealing={isRevealing && guesses.length - 1 === i}
                  />
                </div>
              ) : (
                <></>
              )
            )}

            {guesses.length < MAX_CHALLENGES && !guesses.includes(solution) && (
              <CurrentRow
                key={'crr'}
                guess={currentGuess}
                className={currentRowClassName}
              />
            )}
            {empties.map((_, i) => (
              <EmptyRow key={i} />
            ))}
          </>
        </div>
      </div>
    </div>
  )
}
