import {
  HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
  isMastermind: boolean
  handleMastermind: Function
  isMasked: boolean
  handleMasked: Function
  isLatters: boolean
  handleLatters: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
  isMastermind,
  handleMastermind,
  isMasked,
  handleMasked,
  isLatters,
  handleLatters,
}: Props) => {
  return (
    <BaseModal title="الإعدادات" isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        <SettingsToggle
          settingName="الوضع الصعب"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="وضع الحروف المبعثرة"
          flag={isLatters}
          handleFlag={handleLatters}
          description={'ستكون هناك حروف في بعض الاسطر يجب استخدامها'}
        />
        <SettingsToggle
          settingName="وضع التعمية"
          flag={isMasked}
          handleFlag={handleMasked}
          description={
            'الخانات باللون المختلف لن تنكشف لو كانت بالمكان الصحيح أو الخاطئ أو حرف غير صحيح'
          }
        />
        <SettingsToggle
          settingName="الوضع Mastermind"
          flag={isMastermind}
          handleFlag={handleMastermind}
          description={
            'سيعرض عدد الحروف الموجودة وذات المكان الصحيح دون الكشف عن الحرف نفسه'
          }
        />
        <SettingsToggle
          settingName="الوضع الليلي"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="ألوان متباينة"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
      </div>
    </BaseModal>
  )
}
